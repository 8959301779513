@import "../affix/style/index.less";
@import "../alert/style/index.less";
@import "../anchor/style/index.less";
@import "../auto-complete/style/index.less";
@import "../avatar/style/index.less";
@import "../back-top/style/index.less";
@import "../badge/style/index.less";
@import "../breadcrumb/style/index.less";
@import "../button/style/index.less";
@import "../calendar/style/index.less";
@import "../card/style/index.less";
@import "../carousel/style/index.less";
@import "../cascader/style/index.less";
@import "../checkbox/style/index.less";
@import "../collapse/style/index.less";
@import "../comment/style/index.less";
@import "../config-provider/style/index.less";
@import "../date-picker/style/index.less";
@import "../descriptions/style/index.less";
@import "../divider/style/index.less";
@import "../drawer/style/index.less";
@import "../dropdown/style/index.less";
@import "../empty/style/index.less";
@import "../form/style/index.less";
@import "../grid/style/index.less";
@import "../icon/style/index.less";
@import "../input/style/index.less";
@import "../input-number/style/index.less";
@import "../layout/style/index.less";
@import "../list/style/index.less";
@import "../locale-provider/style/index.less";
@import "../mention/style/index.less";
@import "../mentions/style/index.less";
@import "../menu/style/index.less";
@import "../message/style/index.less";
@import "../modal/style/index.less";
@import "../notification/style/index.less";
@import "../page-header/style/index.less";
@import "../pagination/style/index.less";
@import "../popover/style/index.less";
@import "../progress/style/index.less";
@import "../radio/style/index.less";
@import "../rate/style/index.less";
@import "../result/style/index.less";
@import "../select/style/index.less";
@import "../skeleton/style/index.less";
@import "../slider/style/index.less";
@import "../spin/style/index.less";
@import "../statistic/style/index.less";
@import "../steps/style/index.less";
@import "../switch/style/index.less";
@import "../table/style/index.less";
@import "../tabs/style/index.less";
@import "../tag/style/index.less";
@import "../time-picker/style/index.less";
@import "../timeline/style/index.less";
@import "../tooltip/style/index.less";
@import "../transfer/style/index.less";
@import "../tree/style/index.less";
@import "../tree-select/style/index.less";
@import "../typography/style/index.less";
@import "../upload/style/index.less";

@primary-color: #39a794;@success-color: #47b65d;@info-color: #338adb;@warning-color: #ff8000;@error-color: #e12e3f;@normal-color: #000;@input-height-base: 30px;@btn-height-base: 30px;@border-color-base: #cdd6dd;@input-border-color: #71777e;@control-padding-horizontal: 8px;@checkbox-size: 14px;@link-color: #39a794;@yellow-1: #ffe7b4;@border-radius-base: 3px;@table-header-sort-active-bg: transparent;@font-family: "Open Sans", Helvetica, Arial, sans-serif;@font-size-base: 12px;@text-color: #1b1b1b;@layout-body-background: #fff;@layout-header-background: #3b4b59;@layout-header-height: 60px;